import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { SessionGuardService as SessionGuard } from './services/session-guard.service';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [SessionGuard] },
  { path: 'nosotros', loadChildren: () => import('./pages/why-us/why-us.module').then(m => m.WhyUsModule), canActivate: [SessionGuard] },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), canActivate: [SessionGuard] },
  { path: 'contactanos', loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule), canActivate: [SessionGuard] },
  { path: 'promociones', loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsModule), canActivate: [SessionGuard] },
  { path: 'cursos', loadChildren: () => import('./pages/course-selection/course-selection.module').then(m => m.CourseSelectionModule), canActivate: [SessionGuard] },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'video', loadChildren: () => import('./pages/video/video.module').then(m => m.VideoModule), canActivate: [SessionGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
