import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from './../interfaces/configuration';
import { Response } from './../interfaces/response';
import {API_URL} from './config';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private http: HttpClient
  ) { }
  getConfiguration(){
    const path = `${API_URL}/v1/general_configuration`;
    return this.http.get<Response<Configuration[]>>(path);
  }
}
