<div class="footer-container" [ngClass]="{'light-gray': mode === 'light-gray', 'light-blue': mode === 'light-blue'}">
    <div class="footer" >
        <p class="copyright">Copyright @ 2021 Elipse. Desarrollado por Aegis</p>
        <div class="contact-info">
            <a class="contact-info-link" [href]="configuration['FB']" target="_blank"><img src="../../../assets/images/icons/fb-icon.png"></a>
            <a class="contact-info-link" [href]="configuration['IG']" target="_blank"><img src="../../../assets/images/icons/ig-icon.png"></a>
            <p class="contact-info-link tels-text">
                Tlfs: <a class="inside-text" [href]="'tel:'+configuration['WSP']" target="_blank">{{configuration['WSP']}}</a> 
                <span class="inside-text"> | </span> 
                <a class="inside-text" [href]="'tel:'+configuration['WSP2']" target="_blank">{{configuration['WSP2']}}</a>
            </p>
        </div>
    </div>
</div>
<app-whatsapp-floating [configuration]="configuration"></app-whatsapp-floating>