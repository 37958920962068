import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElipseHeaderComponent } from './elipse-header.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ElipseHeaderComponent],
  imports: [CommonModule, RouterModule],
  exports: [ElipseHeaderComponent],
})
export class ElipseHeaderModule {
  //
}
