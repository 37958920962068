import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from './../../services/configuration.service';

@Component({
  selector: 'app-elipse-footer',
  templateUrl: './elipse-footer.component.html',
  styleUrls: ['./elipse-footer.component.scss']
})
export class ElipseFooterComponent implements OnInit {
  // mode normal or light-gray or light-blue
  @Input() mode = 'normal';
  @Input() configurationParent: {[index: string]: any} = {};
  constructor(
    private configurationService: ConfigurationService
  ) { }
  public configuration: {[index: string]: any} = {
    'FB': '',
    'IG': '',
    'WSP': '',
    'WSP2': '',
    'EMAIL': '',
    'ADDR': '',
  };
  
  getConfiguration(){
    this.configurationService.getConfiguration()
    .subscribe(response => {
      response.data.forEach(value => {
        this.configuration[value.code] = value.content;
      });
    })
  }
  ngOnInit(): void {
    if(Object.keys(this.configurationParent).length>0)
      this.configuration = this.configurationParent;
    else
      this.getConfiguration();
  }
}
