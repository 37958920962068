import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from './../interfaces/response';
import {API_URL} from './config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  session = JSON.parse(localStorage.getItem('session') || '{"token": ""}');
  constructor(
    private http: HttpClient
  ) { }
  login(params:object){
    const path = `${API_URL}/v1/login`;
    return this.http.post<Response<Object[]>>(path, params);
  }
  public isAuthenticated(): boolean {
    return !!this.session.token;
  }
}
