import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Response } from './../interfaces/response';
import { Cycle } from './../interfaces/cycle';
import {API_URL} from './config';
@Injectable({
  providedIn: 'root'
})
export class CycleService {
  private cycleSource = new BehaviorSubject("");
  currentCycle = this.cycleSource.asObservable();
  constructor(
    private http: HttpClient
  ) { }
  getCurrentCycle(){
    const path = `${API_URL}/v1/cycle`;
    return this.http.get<Response<Cycle[]>>(path);
  }
  changeCurrentCycle(cycle: string) {
    this.cycleSource.next(cycle)
  }
}
