import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class UserInterceptor implements HttpInterceptor {
    session = JSON.parse(localStorage.getItem('session') || '{"token": ""}');
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                'Content-Type' : 'application/json; charset=utf-8',
                'Accept'       : 'application/json',
                'Authorization': this.session.token  ? `token ${this.session.token}` : ``,
            },
        });
        return next.handle(req);
    }
}