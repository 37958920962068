import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElipseFooterComponent } from './elipse-footer.component';
import { WhatsappFloatingModule } from 'src/app/components/whatsapp-floating/whatsapp-floating.module';

@NgModule({
  declarations: [
    ElipseFooterComponent
  ],
  imports: [
    CommonModule,
    WhatsappFloatingModule,
  ],
  exports: [
    ElipseFooterComponent
  ]
})
export class ElipseFooterModule { }
