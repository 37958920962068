import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatsappFloatingComponent } from './whatsapp-floating.component';



@NgModule({
  declarations: [
    WhatsappFloatingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    WhatsappFloatingComponent
  ]
})
export class WhatsappFloatingModule { }
