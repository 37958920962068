import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CycleService } from './../../services/cycle.service';

@Component({
  selector: 'app-elipse-header',
  templateUrl: './elipse-header.component.html',
  styleUrls: ['./elipse-header.component.scss'],
})
export class ElipseHeaderComponent implements OnInit, OnDestroy {
  session = JSON.parse(localStorage.getItem('session') || '{"token": ""}');
  sessionStatus = !!this.session.token;
  activeEle = this.router.url;
  windowWidth = 0;
  cycle = '';
  menuSelectActive = '';
  @ViewChild('menu', { static: false })
  menu!: ElementRef;

  @ViewChild('selectMenu', { static: false })
  selectMenu!: ElementRef;

  @Input()
  hiddenOn: string[] = [];

  visible = true;
  loadingCycle = false;

  private readonly routesSubscription: Subscription;

  constructor(
    private render: Renderer2,
    private router: Router,
    private cycleService: CycleService
  ) {
    this.routesSubscription = this.router.events
    .pipe(filter((event: any) => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.visible = !this.hiddenOn.includes(event.url);
      if (this.router.url === '/dashboard/cuenta') {
        this.menuSelectActive = 'Mi Cuenta';
      } else if (this.router.url === '/dashboard/mis-cursos') {
        this.menuSelectActive = 'Mis Cursos';
      }
    });
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.loadingCycle = true;
    this.cycleService.currentCycle.subscribe(cycle => {
      this.cycle = cycle;
      this.loadingCycle = false;
    });
  }
  
  logout(): void{
    localStorage.removeItem('session');
    this.session = {};
    this.sessionStatus = false;
    //this.router.navigate(['/']);
    window.location.href = '/';
  }

  ngOnDestroy(): void {
    this.routesSubscription.unsubscribe();
  }

  activeTab(): void {
    setTimeout(() => {
      this.activeEle = this.router.url;
    }, 300);
    
    if (this.router.url === '/dashboard/cuenta') {
      this.menuSelectActive = 'Mi Cuenta';
    } else if (this.router.url === '/dashboard/mis-cursos') {
      this.menuSelectActive = 'Mis Cursos';
    }
    this.closeSelectMenu();
  }

  toggleMenu(): void {
    if (this.menu.nativeElement.classList[1]) {
      this.render.removeClass(this.menu.nativeElement, 'open');
    } else {
      this.render.addClass(this.menu.nativeElement, 'open');
    }
  }

  toggleSelectMenu(): void {
    if (this.selectMenu.nativeElement.classList[1]) {
      this.render.removeClass(this.selectMenu.nativeElement, 'clicked');
    } else {
      this.render.addClass(this.selectMenu.nativeElement, 'clicked');
    }
  }

  

  @HostListener('window:click', ['$event'])
  onClick(event: any): void {
    const target: HTMLElement = event.target;

    if (this.windowWidth < 768) {
      if (
        //target.className === 'menu-item' ||
        //target.className === 'item-text' ||
        target.className === 'hover-line' ||
        //target.className === 'login' ||
        target.className === 'login-icon' ||
        target.className === 'select-item'  ||
        target.className === 'select-elements' ||
        target.className === 'active'
      ) {
        this.closeMenu();
        this.closeSelectMenu();
      }
    }
  }

  closeSelectMenu(): void {
    if(this.selectMenu){
      this.render.removeClass(this.selectMenu.nativeElement, 'clicked');
    }
  }

  closeMenu(): void {
    this.render.removeClass(this.menu.nativeElement, 'open');
  }
}
