import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-whatsapp-floating',
  templateUrl: './whatsapp-floating.component.html',
  styleUrls: ['./whatsapp-floating.component.scss']
})
export class WhatsappFloatingComponent implements OnInit {
  @Input() configuration: {[index: string]: any} = {};
  constructor() { }

  ngOnInit(): void {
  }

}
