import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserInterceptor } from './interceptors/user.interceptor';
import {
  SocialLoginModule,
  GoogleLoginProvider
} from 'angularx-social-login';
import { JwtModule } from "@auth0/angular-jwt";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ElipseFooterModule } from './components/elipse-footer/elipse-footer.module';
import { ElipseHeaderModule } from './components/elipse-header/elipse-header.module';
import { LightboxModule } from 'ngx-lightbox';

export function tokenGetter() {
  return JSON.parse(localStorage.getItem('session') || '{"token": ""}');;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    ElipseFooterModule,
    ElipseHeaderModule,
    LightboxModule,
    SocialLoginModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200", "http://elipseasesorias.com/"],
        disallowedRoutes: ["http://localhost:4200/dashboard/", "http://elipseasesorias.com/dashboard/"],
      },
    }),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('139956554105-40ka568mb3sfq2rbfhi988cg142p4mht.apps.googleusercontent.com')
          }
        ]
      }
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: UserInterceptor,
      multi   : true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
