<!-- agregar clase user-loged cuando el usuario este logueado a header-container -->
<div *ngIf="visible" class="header-container">
    <div class="header">
        <div class="logo">
            <a href="/"><img  src="../../../assets/images/logo/logo elipse.svg"></a>
        </div>
        <div *ngIf="!sessionStatus" (click)="toggleMenu()" class="list-icon-mobile"></div>
        <div *ngIf="sessionStatus" (click)="logout()" class="list-icon-mobile loged"></div>
        <div *ngIf="sessionStatus" class="mobile-navigation">
            <div class="line-for-loged-element"></div>
            <p class="user-welcome">¡Qué gusto verte por aquí, <span>{{session.first_name + ' ' + session.last_name}}!</span></p>
            <div class="ciclo">
                CICLO {{cycle}}
                <div class="loading" *ngIf="loadingCycle"></div>
            </div>
        </div>
        <div *ngIf="sessionStatus" class="menu-mobile-dashboard">
            <div #selectMenu class="select">
                <div (click)="toggleSelectMenu()" class="box-clicked">
                    <p class="select-title">{{ menuSelectActive }}</p>
                    <span class="arrow-down-blue"></span>
                </div>
                <ul class="select-elements">
                    <li class="select-item" [routerLink]="['/dashboard/mis-cursos']" (click)="activeTab()" routerLinkActive="active">Mis Cursos</li>
                    <li class="select-item" [routerLink]="['/dashboard/cuenta']" (click)="activeTab()" routerLinkActive="active">Mi Cuenta</li>
                </ul>
            </div>
        </div>
        <div #menu class="menu">
            <p *ngIf="sessionStatus" class="user-welcome">¡Qué gusto verte por aquí, <span>{{session.first_name + ' ' + session.last_name}}!</span></p>
            <ul class="menu-desktop">
                <li *ngIf="!sessionStatus" class="menu-item"><a [routerLink]="['/']" (click)="activeTab()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <span class="item-text">
                        Inicio
                        <span class="hover-line"></span>
                    </span>
                </a></li>
                <li *ngIf="!sessionStatus" class="menu-item"><a [routerLink]="['/nosotros']" (click)="activeTab()" routerLinkActive="active">
                    <span class="item-text">
                        ¿Por qué elegirnos?
                        <span class="hover-line"></span>
                    </span>
                </a></li>
                <li *ngIf="!sessionStatus" class="menu-item"><a [routerLink]="['/cursos']"  (click)="activeTab()" routerLinkActive="active">
                    <span class="item-text">
                        Videos demostrativos
                        <span class="hover-line"></span>
                    </span>
                </a></li>
                <li *ngIf="!sessionStatus" class="menu-item"><a [routerLink]="['/promociones']" (click)="activeTab()" routerLinkActive="active">
                    <span class="item-text">
                        Promociones
                        <span class="hover-line"></span>
                    </span>
                </a></li>
                <li *ngIf="!sessionStatus" class="menu-item"><a [routerLink]="['/contactanos']" (click)="activeTab()" routerLinkActive="active">
                    <span class="item-text">
                        Contáctanos
                        <span class="hover-line"></span>
                    </span>
                </a></li>
                <!--
                <li *ngIf="sessionStatus" class="menu-item"><a [routerLink]="['/dashboard/mis-cursos']" (click)="activeTab()" routerLinkActive="active">
                    <span class="item-text">
                        Mis cursos
                        <span class="hover-line"></span>
                    </span>
                </a></li>
                <li *ngIf="sessionStatus" class="menu-item"><a [routerLink]="['/dashboard/cuenta']" (click)="activeTab()" routerLinkActive="active">
                    <span class="item-text">
                        Mi cuenta
                        <span class="hover-line"></span>
                    </span>
                </a></li>
                -->
                <li *ngIf="!sessionStatus" class="menu-item menu-item-black"><a class="login" [routerLink]="['/login']">
                    <span class="login-icon"></span>Iniciar Sesión
                </a></li>
                <li *ngIf="sessionStatus" (click)="logout()" class="menu-item menu-item-black menu-item-close"><a class="logout">
                    <span class="login-icon"></span>Cerrar Sesión
                </a></li>
            </ul>
        </div>
    </div>
</div>
